<template lang="html">
    <div class="container-fluid p-5">
        <div class="row mt-2">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">Member</div>
                    <div class="card-body">
                        <p class="card-text">{{dashboard.member}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">Product</div>
                    <div class="card-body">
                        <p class="card-text">{{dashboard.product}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">Pending</div>
                    <div class="card-body">
                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;" @click="toOrder(0)">{{dashboard.pending}}</a></p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">Confirm</div>
                    <div class="card-body">
                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;" @click="toOrder(1)">{{dashboard.confirm}}</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-3">
                <div class="card" style="width: 100%;">
                     <div class="card-header">Reject</div>
                    <div class="card-body">
                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;" @click="toOrder(1)">{{dashboard.reject}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
    export default {
        name: 'dashboard',
        metaInfo: {
            title: "Home",
            titleTemplate: "%s ← SHRE Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                dashboard: [],
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                dashboardAction: 'dashboardAction',
            }),
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardAction().then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            toOrder(status) {
                this.$router.replace({ path: 'order-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at', limit: 10, fromDate: '', toDate: '', status: status, userId: '', email: '', orderNo: '' } }).catch(()=>{})
            },
        },
        async mounted() {
            this.isLoading = true
            this.dashboardDetail()
        }
    }
</script>


<style scoped lang="scss">
</style>